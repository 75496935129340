<template>
	<div class="dashboard-creation">

				<Head :title="Head.Title" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight"
			:subTitle="Head.SubTitle" :subTitle2="Head.SubTitle2" :bgUrl="Head.bgUrl" :bgUrlLazy="Head.bgUrlLazy"
			:offset="Head.offset" :height="Head.height" :overlayOpacity="Head.overlayOpacity"
			:overlayColor="Head.overlayColor" :imgBlur="Head.imgBlur" :transitionRotate="Head.transitionRotate"
			:titleColor="Head.TitleColor" />

		<v-container>
			<h1 class="display-1 mb-5">Gestion rôles utilisateurs</h1>
			<v-row>
				<v-col cols="6">
					<v-card elevation="2">
						<v-card-title>Ajouter un rôle à un utilisateur</v-card-title>

						<v-card-text>
							<v-row>
								<v-col cols="6">
									<v-autocomplete v-model="userGetRole.user" :items="users" item-text="label" item-value="value" label="Utilisateur"></v-autocomplete>
								</v-col>
								<v-col cols="6">
									<v-autocomplete v-model="userGetRole.role" :items="ranks" item-text="label" item-value="id" label="Rôle"></v-autocomplete>
								</v-col>
							</v-row>

							<v-btn @click="addRoleToUser" outlined color="primary">Ajouter le rôle à cet utilisateur</v-btn>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="6">
					<v-card elevation="2">
						<v-card-title>Créer un rôle</v-card-title>

						<v-card-text>
							<v-form class="form">
								<v-row>
									<v-col cols="12">
										<v-text-field label="Nom" v-model="rankInfo.name" :rules="this.$fieldsRules.required('nom')"></v-text-field>
										<v-checkbox v-model="rankInfo.administration" label="Accès à l'administration"></v-checkbox>
									</v-col>
								</v-row>
							</v-form>

							<v-btn @click="addRole" outlined color="primary">Créer le rôle</v-btn>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col cols="6">
					<v-card elevation="2">
						<v-card-title>Supprimer un rôle à un utilisateur</v-card-title>

						<v-card-text>
							<v-row>
								<v-col cols="6">
									<v-autocomplete v-model="userRemoveRole.user" :items="users" item-text="label" item-value="value" label="Utilisateur"></v-autocomplete>
								</v-col>
								<v-col cols="6">
									<v-autocomplete v-model="userRemoveRole.role" :items="ranks" item-text="label" item-value="id" label="Rôle"></v-autocomplete>
								</v-col>
							</v-row>

							<v-btn @click="removeRoleToUser" outlined color="error">Retirer le rôle à cet utilisateur</v-btn>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="6">
					<v-card elevation="2">
						<v-card-title>Rôles</v-card-title>

						<v-card-text>
							<v-list subheader two-line>
								<v-list-item v-for="rank in ranks" :key="rank.id">
									<v-list-item-content>
										<v-list-item-title>{{ rank.label }} <small v-show="!rank.enabled">...en pause</small></v-list-item-title>
									</v-list-item-content>

									<v-list-item-action>
										<v-row>
											<v-btn icon>
												<v-icon color="primary" v-if="rank.enabled" @click="statusRank(rank, false)">mdi-pause</v-icon>
												<v-icon color="warning" v-else @click="statusRank(rank, true)">mdi-play</v-icon>
											</v-btn>
											<v-btn icon>
												<v-icon color="secondary" @click="adminRank(rank, !rank.administration)">mdi-shield-star</v-icon>
											</v-btn>
											<v-btn icon>
												<v-icon color="red" @click="deleteRank(rank)">mdi-close</v-icon>
											</v-btn>
										</v-row>
									</v-list-item-action>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-divider class="my-4"></v-divider>
			
			<v-row>
				<h1 class="display-1 my-5 col-12">Gestion modules entreprises</h1>
				<v-col cols="6">
					<v-card elevation="2">
						<v-card-title>Gestion module</v-card-title>

						<v-card-text>
							<v-row>
								<v-col cols="12" sm="6">
									<v-form class="form">
										<v-row>
											<v-col cols="12">
												<v-text-field label="Nom" v-model="newModule" :rules="this.$fieldsRules.required('nom')"></v-text-field>
											</v-col>
										</v-row>
									</v-form>

									<v-btn @click="addModule()" outlined color="success">Créer le module</v-btn>
								</v-col>
								<v-col cols="12" sm="6">
									<v-form class="form">
										<v-row>
											<v-col cols="12">
												<v-autocomplete v-model="moduleToRemove" :items="modules" item-text="name" item-value="id" label="Module"></v-autocomplete>
											</v-col>
										</v-row>
									</v-form>

									<v-btn @click="dialog.deleteModule = true" outlined color="error">Supprimer le module</v-btn>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
					
					<v-card elevation="2" class="mt-6">
						<v-card-title>Modules actifs</v-card-title>

						<v-card-text>
							<div v-for="item in modules" :key="item.id">
								<p>{{item.name}} - {{item.id}}</p>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="6">
					<v-card elevation="2">
						<v-card-title>Gérer une entreprise</v-card-title>

						<v-card-text>
							<v-autocomplete v-model="companyGet" :items="companies" item-text="label" item-value="value" label="Entreprise"></v-autocomplete>

							<template v-if="mainCompany.id">
								{{ this.mainCompany.name }} - {{ this.mainCompany.id }}

								<v-list-item v-for="module in companyModules" :key="module.id">
									<v-list-item-content>
										<v-list-item-title>{{ module.name }}</v-list-item-title>
										<v-list-item-subtitle>{{ module.value }}</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-btn small outlined color="success" class="my-1" v-if="!module.active" @click="statusModuleCompany(module.id, true)">Activer</v-btn>
										<v-btn small outlined color="error" class="my-1" v-else @click="statusModuleCompany(module.id, false)">Désactiver</v-btn>
									</v-list-item-action>
								</v-list-item>
							</template>

						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>

		<v-dialog v-model="dialog.deleteModule" max-width="70vw">
			<v-card>
				<v-card-title class="headline">
					Voulez-vous supprimer ce module ?
				</v-card-title>
				<v-card-text>
					<p>Après avoir cliqué sur "Confirmer la suppression", tu comprends bien que toutes les entreprises avec ce module bah, auront plus l'accès quoi :x</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="error" text @click="removeModule()">
						Confirmer la suppression
					</v-btn>
					<v-btn color="primary" text @click="dialog.deleteModule = false">
						Annuler
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import Head from '@/components/graphics/head'

	export default {
		head: {
			title: {
				inner: "Création"
			}
		},
		components: {
			Head,
		},
		data() {
			return {

				Head: {

					Title: 'Gestion des rôles',
					baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
					TitleWeight: 'thin', // thin / light / regular / medium / bold / black
					TitleColor: 'white', // white / black / primary

					SubTitle: '',
					SubTitle2: "",

					bgUrl: "https://images.unsplash.com/photo-1509023464722-18d996393ca8?auto=format&fit=crop&w=1950",
					bgUrlLazy: "https://images.unsplash.com/photo-1509023464722-18d996393ca8?auto=format&fit=crop&w=1950",

					offset: "10vh",
					height: "65vh",

					overlayOpacity: "0.3",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"

				},

				userInfo: this.$models.user,

				companyInfo: this.$models.company,

				rankInfo: this.$models.rank,

				userGetRole: {},
				userRemoveRole: {},

				companyGet: {},
				companyModules: [],
				mainCompany: this.$models.company,

				newModule: '',
				moduleToRemove: '',

				modules: [],
				companies: [],
				ranks: [],
				ranksList: [],
				users: [],

				dialog: {
					deleteModule: false
				},
			};
		},
		methods: {
			async statusRank(rank, status){
				try {
					await this.$db.collection('ranks').doc(rank.id).set({
						'enabled': status
					}, {
						merge: true
					}).then(() => {
						this.$store.dispatch('setSnackbar', {
							text: `Le rôle ${rank.name} a bien été modifié.`,
						});
					});
				} catch (err) {
					this.$store.dispatch('setSnackbar', {
						text: `Erreur: ${err.message}`,
						color: 'danger'
					});
				}
			},
			async adminRank(rank, status){
				try {
					await this.$db.collection('ranks').doc(rank.id).set({
						'administration': status
					}, {
						merge: true
					}).then(() => {
						this.$store.dispatch('setSnackbar', {
							text: `Le rôle ${rank.name} a bien été modifié.`,
						});
					});
				} catch (err) {
					this.$store.dispatch('setSnackbar', {
						text: `Erreur: ${err.message}`,
						color: 'danger'
					});
				}
			},
			async deleteRank(rank){
				try {
					await this.$katana.removeAllDataFromDocument(this.$db.collection('ranks').doc(rank.id), `Le rôle ${rank.name} a bien été supprimé.`);
				} catch (err) {
					this.$store.dispatch('setSnackbar', {
						text: `Erreur: ${err.message}`,
						color: 'danger'
					});
				}
			},

			async addRole(){
				try {
					let rank = {
						...this.rankInfo
					};

					await this.$db.collection('ranks').add(rank);

					this.$store.dispatch('setSnackbar', {
						text: `Le rôle ${this.rankInfo.name} a bien été créé.`,
					});
				} catch (err) {					
					this.$store.dispatch('setSnackbar', {
						text: `Erreur: ${err.message}`,
						color: 'danger'
					});
				}
			},

			async addRoleToUser(){
				try {
					await this.$db.collection('users').doc(this.userGetRole.user).collection('ranks').doc(this.userGetRole.role).set({
						'giveDate': new Date()
					}, {
						merge: true
					}).then(() => {
						this.$store.dispatch('setSnackbar', {
							text: `Ce rôle a bien été ajouté à cet utilisateur.`,
						});

						this.userGetRole = {};
					});
				} catch (err) {
					this.$store.dispatch('setSnackbar', {
						text: `Erreur: ${err.message}`,
						color: 'danger'
					});
				}
			},

			removeRoleToUser(){
				try {
					this.$katana.removeAllDataFromDocument(this.$db.collection('users').doc(this.userRemoveRole.user).collection('ranks').doc(this.userRemoveRole.role), `Ce rôle a bien été retiré de cet utilisateur.`);

					this.userRemoveRole = {};
				} catch (err) {
					this.$store.dispatch('setSnackbar', {
						text: `Erreur: ${err.message}`,
						color: 'danger'
					});
				}
			},

			async addModule(){
				try {
					await this.$db.collection('modules').add({
						name: this.newModule.toUpperCase(),
						creationDate: new Date()
					});

					this.$store.dispatch('setSnackbar', {
						text: `Le module ${this.newModule} a bien été créé.`,
					});

					this.newModule = '';
				} catch (err) {					
					this.$store.dispatch('setSnackbar', {
						text: `Erreur: ${err.message}`,
						color: 'danger'
					});
				}

				this.getModules();
			},

			async removeModule(){
				this.$katana.removeAllDataFromDocument(this.$db.collection("modules").doc(this.moduleToRemove), `Le module à bien été supprimé !`);

				this.getModules();
			},

			statusModuleCompany(idModule, activate){
				this.$db.collection("companies").doc(this.companyGet).set({ modulesModified: new Date() }, { merge: true });

				if(activate) {
					this.$db.collection("companies").doc(this.companyGet).collection("modules").doc(idModule).set({
						giveDate: new Date()
					}).then(() => {
						this.$store.dispatch('setSnackbar', {
							text: `Le module à bien été activé pour cette entreprise !`,
						});
					});
				}
				else this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.companyGet).collection("modules").doc(idModule), `Le module à bien été désactivé pour cette entreprise !`);

				this.companyModules = this.getModulesForCompany(this.companyGet);
			},

			getModulesForCompany(idCompany) {
				var companyModules = [];
				this.$db.collection("modules").get().then((ref) => {							
					ref.forEach((modDoc) => {
						this.$db.collection("companies").doc(idCompany).collection("modules").doc(modDoc.id).get().then((modCompDoc) => {
							companyModules.push({
								name: modDoc.data().name,
								id: modDoc.id,
								active: modCompDoc.data() ? true : false
							});
						});
						
					});
				});

				return companyModules;
			},

			getModules() {
				this.modules = [];
				this.$db.collection("modules").get().then((ref) => {							
					ref.forEach((modDoc) => {
						this.modules.push({
							...modDoc.data(),
							id: modDoc.id
						});
					});
				});
			}
		},
		watch: {
			companyGet() {
				this.$db.collection("companies").doc(this.companyGet).get().then((doc) => {
					this.mainCompany = {
						...doc.data(),
						id: doc.id
					};

					this.companyModules = this.getModulesForCompany(this.companyGet);
					
				});
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = doc.data();
			});

			this.currentUser = this.$firebase.auth().currentUser;

			this.$db.collection("users").orderBy("lastName", "asc").onSnapshot((res) => {
				this.users = [];

				res.forEach((document) => {
					var data = document.data()

					this.users.push({
						label: `${data.lastName.toUpperCase()} ${data.firstName}`,
						value: document.id
					});
				});
			});

			this.$db.collection("companies").orderBy("name", "asc").onSnapshot((res) => {
				this.companies = [];

				res.forEach((document) => {
					if(document.data().belongTo) return;

					this.companies.push({
						...document.data(),
						label: document.data().name,
						value: document.id,
						id: document.id
					});
				});
			});

			this.getModules();

			this.$db.collection("ranks").orderBy("name", "asc").onSnapshot((res) => {
				this.ranksList = [];
				this.ranks = [];

				res.forEach((document) => {
					var data = document.data();

					this.ranksList.push({
						label: `${data.name} (Admin: ${data.administration ? "✔️" : "❌"})`,
						value: document.id
					});

					this.ranks.push({
						...data,
						label: `${data.name} (Admin: ${data.administration ? "✔️" : "❌"})`,
						id: document.id
					});
				});
			});
		}
	}
</script>